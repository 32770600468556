export const setToLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

export const deleteFromLocalStorage = (name: string): void => {
  localStorage.removeItem(name);
};

export const getFromLocalStorage = (name: string): string | null => {
  return localStorage.getItem(name) || null;
};

export const setObjectToLocalStorage = (name: string, data: unknown): void => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const getObjectFromLocalStorage = <T>(name: string): T | null => {
  const data = getFromLocalStorage(name);
  if (data) {
    try {
      return JSON.parse(data) as T;
    } catch (e) {
      return null;
    }
  }
  return null;
};

const endpoints = {
  APP_CONFIG: '/api/landing/manifests/app-config/',
  ARTICLE: (id: string) => `/api/landing/home/blog-articles/${id}/`,
  ARTICLES: '/api/landing/home/blog-articles/',
  CONTACT_US: '/api/landing/home/contact-us/',
  EXERCISE_CONFIG: '/api/landing/manifests/exercise-config/',
  LOGIN: '/api/users/login/',
  NEWSLETTER_SUBSCRIBE: '/api/landing/home/newsletter/subscribe/',
  PODCAST: (id: string) => `/api/landing/home/podcasts/${id}/`,
  PODCASTS: '/api/landing/home/podcasts/',
  REFRESH_TOKEN: '/api/users/token-refresh/',
  REQUEST_DEMO: '/api/landing/home/request-demo/',
  TEST_QUESTIONNAIRE_RECORDS: (id: string) => `/api/landing/questionnaires/testquestionnaires/${id}/`,
};
export default endpoints;

import classNames from 'classnames';
import * as React from 'react';

import styles from './Icon.module.scss';

export enum IconSizes {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  none = 'none',
}

export const sizes = {
  [IconSizes.xs]: {
    width: 15,
    height: 15,
  },
  [IconSizes.s]: {
    width: 25,
    height: 25,
  },
  [IconSizes.m]: {
    width: 35,
    height: 35,
  },
  [IconSizes.l]: {
    width: 100,
  },
  [IconSizes.xl]: {
    width: 140,
  },
  [IconSizes.none]: {},
};

export interface IconProps {
  icon: string;
  size?: IconSizes;
  className?: string;
  spin?: boolean;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const { icon, size = IconSizes.none, className = null, spin = false } = props;

  // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-member-access
  const IconSvg = require(`!!@svgr/webpack?-svgo,+titleProp,+ref!@Images/icons/${icon}.svg`)
    .default as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

  return (
    <IconSvg {...sizes[size]} className={classNames(styles.icon, `icon-${size}`, { [styles.spin]: spin }, className)} />
  );
};
export default Icon;

import HeroImage from '@Images/heroImage.png';
import { translate } from '@Utils/i18n';
import { getInternalFullUrl } from '@Utils/urls';

export enum LocalStorage {
  userToken = 'userToken',
  refreshToken = 'refreshToken',
  lang = 'lang',
}

export enum DateFormat {
  defaultDateFormat = 'dd MMMM yyyy',
  fullDate = 'E, dd MMM yyyy',
  fullDateWithTime = 'E, dd MMM yyyy, HH:mm a',
  fullDateWithTwelveHoursTime = 'E, dd MMM yyyy, h:mm a',
  monthWithYear = 'MMM yyyy',
  monthDay = 'MMM dd',
  fullMonthWithYear = 'MMMM yyyy',
  shortDateWithHours = 'dd MMM yyyy, h:mm a',
  twelveHoursFormat = 'h:mm a',
}

export enum MobilePlatform {
  ios = 'ios',
  android = 'android',
}

export const META_DEFAULTS = {
  title: translate('msg_default_meta_title') || '',
  description: translate('msg_default_meta_description') || '',
  type: 'website',
  url: window.location.origin,
  image: getInternalFullUrl(HeroImage),
};

export const DEFAULT_PHONE_NUM_REGION = 'ae';

export const APP_DOWNLOAD_URLS = {
  [MobilePlatform.ios]: 'https://apps.apple.com/kg/app/mindtales/id1534264474',
  [MobilePlatform.android]: 'https://play.google.com/store/apps/details?id=com.mindtalesapp',
};

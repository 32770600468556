import { FormikHelpers } from 'formik';
import { StringMap, TOptions } from 'i18next';

import { MessageKey } from '@Utils/i18n';

export enum LoadingState {
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected',
  idle = 'idle',
}

export type Translator = (msg: MessageKey, options?: TOptions<StringMap>) => string;

export type MapT<V> = { [key: string]: V };

export function isString(value: unknown): value is string {
  return typeof value === 'string' || value instanceof String;
}

export function isFileOrBlob(value: unknown): value is File | Blob {
  return value instanceof Blob || value instanceof File;
}

export type ErrorResponse = { non_field_errors?: [] } & { [x: string]: string[] };

export type ApiFormattedError = { rejectValue: MappedErrorType };

export type MappedErrorType = {
  nonFieldErrors?: string[];
  fieldErrors?: { [x: string]: string[] };
};

export function hasOwnProperty<T>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj;
}

export type FormDataFromEnum<E extends string | number | symbol> = { [field in E]: string };

export type SubmitAction<FormData> = (
  values: FormData,
  formikHelpers: FormikHelpers<FormData>,
) => void | Promise<unknown>;

export enum EnabledLanguageCodes {
  en = 'en',
  ar = 'ar',
  ru = 'ru',
  ua = 'ua',
}

export enum Directions {
  ltr = 'ltr',
  rtl = 'rtl',
}

export type Language = {
  code: EnabledLanguageCodes;
  name: string;
  flag: string;
};

export interface PaginatedResult<T> {
  count: number;
  limit: number;
  offset: number;
  totalPages: number;
  currentPage: number;
  results: T[];
}

export interface Article {
  pk: number;
  title: string;
  english_title: string;
  content: string;
  image: string;
  facebook_url: string;
  twitter_url: string;
  duration: number;
  view_count: number;
  created_at: string;
  seo_content: string;
}

export interface Podcast {
  id: number;
  title: string;
  english_title: string;
  content: string;
  image: string;
  audio_file: string;
  track_length: string;
  created_at: string;
}

export interface ExerciseAnswer {
  id: number;
  translation: string;
}

export interface QuestionnaireExercise {
  id: number;
  exercise_id: number;
  item_type: string;
  translation: string;
  answers: ExerciseAnswer[];
}

export interface Questionnaire {
  id: number;
  estimated_duration: number;
  exercise_type: string;
  translation: string;
  description_translation?: string;
  order_num: number;
  configuration_by_exercise_type: QuestionnaireExercise[];
}

export type QuestionnaireConfig = {
  [key in EnabledLanguageCodes]: {
    anxiety_questionnaire: Questionnaire;
    depression_questionnaire: Questionnaire;
    personality_type_questionnaire: Questionnaire;
  };
};

export default {
  msg_default_website_title: 'مايندتيلز',
  msg_default_meta_title: 'سلامة صحتك النفسية في جيبك',
  msg_default_meta_description:
    'الرعاية الذاتية ليست مجرد ضماد خارجي يخفي ما تحته. نحن في مايندتيلز نريدك أن تستثمر في صحتك العاطفية على المدى البعيد. نضع بين يديك برنامج دعم نفسي سهل الاستخدام يمكن الوصول إليه عبر الإنترنت في أي وقت ومن أي مكان. تحدث مع المختصين المتواجدين على منصتنا من راحة منزلك!',
  msg_img_waving_man: 'رجل يلوح',
  msg_img_man_sitting_with_mindtales: 'رجل يجلس وتطبيق مايندتيلز مفتوح على هاتفه',
  msg_img_open_letter: 'ظرف مفتوح وفي داخله رسالة مع خلفية ملونة',
  msg_img_success_star: 'نجمة صفراء اللون تظهر بعد التسجيل بنجاح للحصول على النشرة الإخبارية',
  msg_img_success_thumb: 'علامة الإبهام تظهر بعد التسجيل بنجاح للحصول على النشرة الإخبارية',
  msg_img_mobile_app_schedule_sesion: 'تطبيق مايندتيلز وتظهر شاشة جدولة الجلسة القادمة',
  msg_img_mobile_app_meditation: 'تطبيق مايندتيلز وتظهر شاشة التأمل',
  msg_img_feedback: 'شخص خاض تجربة غيرت حياته بمساعدة تطبيق مايندتيلز',
  msg_img_conversation: 'محادثة بين شخصين',
  msg_img_walking_with_IPhone: 'شخص يحمل آيفون وتطبيق مايندتيلز مفتوح على شاشته',
  msg_img_employers_feedback: 'أصحاب الأعمال الذين تحدثوا عن رأيهم بمايندتيلز',
  msg_img_therapist_page: 'صفحة المعالج بعد تسجيل الدخول',
  msg_img_accenture: 'دقيق',
  msg_img_goal: 'خط النهاية مع إشارة هدف',
  msg_btn_get_access: 'اشترك الآن',
  msg_btn_submit: 'قدم',
  msg_btn_learn_more: 'اعرف أكثر',
  msg_btn_request_demo: 'اطلب عرضاً',
  msg_btn_check_our_toolkit: 'اطلع على الكتيب الخاص بنا',
  msg_btn_accept: 'قبول',
  msg_btn_decline: 'رفض',
  msg_btn_next: 'التالي',
  msg_field_email_label: 'البريد الإلكتروني',
  msg_field_email_label_req: 'البريد الإلكتروني*',
  msg_field_email_placeholder: 'أدخل بريدك الإلكتروني',
  msg_field_work_email_label: 'البريد الإلكتروني',
  msg_field_work_email_label_req: 'البريد الإلكتروني*',
  msg_field_work_email_placeholder: 'أدخل بريدك الإلكتروني',
  msg_field_name_label: 'الاسم',
  msg_field_name_label_req: 'الاسم*',
  msg_field_name_placeholder: 'أدخل اسمك',
  msg_field_phone_label: 'رقم الهاتف',
  msg_field_phone_label_req: 'رقم الهاتف*',
  msg_field_phone_placeholder: 'أدخل رقم هاتفك',
  msg_field_how_did_you_hear_about_us: 'كيف سمعت عنا؟',
  msg_field_how_did_you_hear_about_us_req: 'كيف سمعت عنا؟*',
  msg_field_message: 'الرسالة',
  msg_field_message_req: 'الرسالة',
  msg_field_message_placeholder: 'رسالتك لنا',
  msg_modal_request_demo_title: 'سنكون سعيدين بانضمامك إلينا!',
  msg_success_wizard_title: 'نجاح',
  msg_success_wizard_subtitle: 'أكملت الاستبيان بنجاح',
  msg_success_wizard_email_title: 'أدخل بريدك الإلكتروني كي ترى نتيجتك',
  msg_success_wizard_email_subtitle: 'اشترك للحصول على نشرتنا الإخبارية',
  msg_error_field_required: 'مطلوب',
  msg_error_passwords_not_equal: 'كلمتا السر غير متطابقتين',
  msg_error_invalid_email: 'عنوان البريد الإلكتروني غير صالح',
  msg_error_unexpected: 'خطأ غير متوقع',
  msg_error_already_verified: 'تم التحقق من المستخدم',
  msg_error_password_key_expired: 'رمز كلمة المرور لم يعد صالحا',
  msg_error_exercise_is_locked: 'التمرين مقفل',
  msg_error_exercise_is_deleted: 'تم مسح التمرين',
  msg_error_not_found: 'لم يتم العثور',
  msg_error_bad_credentials: 'بيانات الدخول غير صالحة',
  msg_error_login_user_email_not_verified: 'لم يتم التحقق من البريد الإلكتروني',
  msg_error_no_such_language: 'هذه اللغة غير متوفرة',
  msg_error_password_is_incorrect: 'كلمة المرور غير صحيحة',
  msg_error_user_id_is_not_in_token: 'الرمز غير صالح',
  msg_error_login_invalid_user_type: 'لا يسمح لك بالدخول إلى المنصة فأنت لست من ضمن الفئات المسموحة',
  msg_error_email_already_exist: 'البريد الإلكتروني مستخدم',
  msg_error_invalid_phone_format: 'رقم الهاتف غير صالح',
  msg_error_invalid_date: 'بيانات خاطئة',
  msg_error_birth_date_should_be_in_past: 'يجب أر يكون تاريخ الميلاد في الماضي',
  msg_error_member_exists: 'لقد قمت بالاشتراك مسبقا ',
  msg_password_requirement_length: 'أكثر من 8 أحرف ',
  msg_password_requirement_upper_and_lower_letters: 'أحرف كبيرة وصغيرة ',
  msg_password_requirement_numbers_or_symbols: 'تحتوي على رقم أو رمز ',
  msg_time_display_0: '{{time}} د',
  msg_time_display_1: 'دقيقة',
  msg_time_display_2: 'دقيقتان',
  msg_time_display_3_10: '{{time}} دقائق',
  msg_time_display_11: '{{time}} دقيقة',
  msg_default_page: 'صفحة ',
  msg_home_page_title: 'منصة رائدة للتعافي النفسي للموظفين في منطقة الشرق الأوسط وشمال أفريقيا',
  msg_about_us_page: 'من نحن',
  msg_how_it_works_page: 'آلية عمل مايندتيلز',
  msg_knowledge_hub_page: 'واحة المعرفة',
  msg_article_page: 'صفحة المقالات',
  msg_podcast_page: 'صفحة المدونات الصوتية',
  msg_employers_page: 'صفحة أصحاب الأعمال ',
  msg_therapists_page: 'للأخصائيين ',
  msg_healthcare_providers_page: 'لمزودي الرعاية الصحية ',
  msg_contact_us_page: 'تواصل معنا ',
  msg_faq_page: 'الأسئلة الشائعة ',
  msg_terms_page: 'الشروط والأحكام ',
  msg_privacy_page: 'سياسة الخصوصية ',
  msg_careers_page: 'الوظائف ',
  msg_community_page: 'الإرشادات المجتمعية ',
  msg_not_found_page_title: 'لم يتم العثور ',
  msg_page_with_param_page_title: 'صفحة مع مؤشرات الإدخال ',
  msg_get_access_page: 'اشترك الآن',
  msg_careers_page_title: 'الوظائف المتاحة لدى Midtales',
  msg_guidelines_page_title: 'المسؤولية المجتمعية لدى Mindtales',

  // Pages meta descriptions
  msg_home_page_meta_description:
    'احجز موعداًعبر الإنترنت من أي مكان حول العالم مع أفضل الأخصائيين في أبوظبي ودبي عبر منصة Mindales، أول منصة تعني بالصحة النفسية في المنطقة، مع خصوصية وسرية تامة 100%. أفضل تقنيات العلاج النفسي والعلاج المعرفي السلوكي للاكتئاب والقلق وغيرها من الاضطرابات النفسية.',
  msg_how_it_works_page_meta_description:
    'تقدم منصة Mindtales أفضل خدمات الدعم النفسي عبر الإنترنت. تواصل مع أفضل الأخصائيين في مجال الصحة النفسية واللايف كوتشينج من راحة منزلك. حمل التطبيق الآن!',
  msg_knowledge_hub_page_meta_description:
    'مقالات علمية مستندة إلى آخر الأبحاث. تمارين تدساعد على التعافي النفسي. أهداف شخصية وتتبع مستمر للعادات اليومية. تمارين تأمل موجهة للمبتدئين والمتقدمين.',
  msg_employers_page_meta_description:
    'برنامج تعافي نفسي عن بعد للموظفين. برنامج تعافي نفسي عبر الإنترنت يقدم جلسات إرشاد نفسي على مدار الساعة 24/7، تدريب شخصي، وتمارين تأمل ويوغا موجهة.',
  msg_therapists_page_meta_description:
    'يمكن للأخصائيين تقديم أن يقدموا برامج ودورات تساعد في الوصول إلى التعافي النفسي من راحة منازلهم. خدمات دعم نفسي عبر الإنترنت تساعد في التخلص من معوقات النجاح وتقدم أفضل الحلول',
  msg_contact_us_page_meta_description:
    'هل تبحث عن أفضل علاج نفسي للاكتئاب في دبي؟ تقدم منصة Mindtales أفضل خدمات الدعم النفسي عبر الإنترنت. تواصل معنا الآن! حمل تطبيق التعافي النفسي الأول الآن!',
  msg_about_us_page_meta_description:
    'تقدم منصة Mindtales أفضل خدمات الإرشاد النفسي لعلاج الاكتئاب في أبوظبي. ويقدم تطبيقنا المتخصص في الصحة النفسية أفضل تقنيات الدعم النفسي في أي وقت وأي مكان.',
  msg_faq_page_meta_description: 'الأسئلة الشائعة. ما هي منصة MIndtales؟ هل منصة Mindtales هي المكان الأنسب لي؟',
  msg_terms_page_meta_description:
    'فيما يلي الشروط والأحكام "الاتفاقية" التي تحكم وتضبط استخدامك ودخولك لمنصتنا التي يتم تقديم خدمات الإرشاد النفسي من خلالها.',
  msg_privacy_page_meta_description:
    'مرحباً بك في سياسة الخصوصية الخاصة بمنصة MIndtales. نحن في منصة Mindtales نحترم خصوصيتك ونلتزم بحماية بياناتك الشخصية.',
  msg_careers_page_meta_description:
    'للاطلاع على الوظائف المتاحة الرجاء زيارة صفحتنا على منصة LinkedIn. في حال رغبت في التواصل معنا، يمكنك إرسال سيرتك الذاتية عبر البريد الإلكتروني careers@mindtales.me',
  msg_guidelines_page_meta_description:
    'المسؤولية المجتمعية لدى Mindtales. نثرة عامة. نحن نبذل قصارى جهدنا لجعل منصة MIndtales والصفحات الاجتماعية مساحة آمنة ومحفزة',

  msg_landing_vs: 'الاستشارات عبر الإنترنت مقابل الاستشارات وجهاً لوجه',

  msg_vs_table_mindtales_only: 'الاستشارات عبر منصة مايندتيلز',
  msg_vs_table_face_to_face: 'الاستشارات وجهاً لوجه',
  msg_vs_table_checklist1: 'تواصل مع أخصائي صحة نفسية مرخص',
  msg_vs_table_checklist2: 'مقابلات بالحضور الشخصي',
  msg_vs_table_checklist3: 'جلسات بالاتصال الصوتي والفيديو',
  msg_vs_table_checklist4: 'إمكانية جدولة المواعيد والملاحظات بنقرة زر واحدة',
  msg_vs_table_checklist5: 'التبديل بين الاستشاريين وأخصائيي الصحة النفسية أينما وجدوا حول العالم',
  msg_vs_table_checklist6: 'الحصول على المساعدة في أي وقت ومن أي مكان',
  msg_vs_table_checklist7: ' تطابق مثالي بين الاستشاري والعميل',
  msg_vs_table_checklist8: 'إمكانية الوصول إلى تمارين تشبه الألعاب للمساعدة في الدعم النفسي',
  msg_vs_table_checklist9: 'مجتمع يضم أفراداً يشابهونك في التفكير والأهداف',
  msg_home_hero_title: 'سلامة صحتك النفسية في جيبك',
  msg_home_hero_subtitle: 'برنامج تعافي نفسي عن بعد ',
  msg_home_hero_text:
    'الرعاية الذاتية ليست مجرد ضماد خارجي يخفي ما تحته. نحن في مايندتيلز نريدك أن تستثمر في صحتك العاطفية على المدى البعيد. نضع بين يديك برنامج دعم نفسي سهل الاستخدام يمكن الوصول إليه عبر الإنترنت في أي وقت ومن أي مكان. تحدث مع المختصين المتواجدين على منصتنا من راحة منزلك!',
  msg_home_how_it_works_title: 'آلية عمل مايندتيلز',
  msg_home_how_it_works_sub1_title: 'خصوصية وأمان',
  msg_home_how_it_works_sub1_text:
    'كل تفاعل تقوم به عبر منصة مايندتيلز مشفر بالكامل. لا تتم مشاركة معلوماتك إطلاقاً، وجميع بياناتك محمية بموجب القانون.',
  msg_home_how_it_works_sub2_title: 'ألعاب مثبتة علمياً تختص بالصحة النفسية',
  msg_home_how_it_works_sub2_text:
    'يمكنك الوصول إلى ألعاب ومصادر علمية تساعدك على استكشاف وتعزيز صحتك النفسية. نصمم تجربتك كي تكون مناسبة لاحتياجاتك الشخصية باستخدام أدوات تم تطويرها من قبل مختصين.',
  msg_home_how_it_works_sub3_title: 'تواصل مع مختصين',
  msg_home_how_it_works_sub3_text:
    'اختر من مجموعة واسعة من الاستشاريين وأخصائيي الصحة النفسية المتواجدين معنا ممن مختلف البلدان. شارك وتفاعل على المنصة بسرية تامة ومن راحة منزلك',
  msg_home_boost_your_health_title: 'عزز جودة صحتك العاطفية',
  msg_home_boost_your_health_text:
    'نحن نعلم أن الدعم النفسي يمكنه حقا أن يحدث فارقا كبيرا في لحظات معدودة. لذلك قمنا باختيار كل خاصية وكل جزئية سواء على موقعنا أو تطبيقنا الإلكتروني بعناية من أجل راحتك ورفاهيتك.',
  msg_home_boost_your_health_sub1_title: 'واحة معرفة تحتوي على مقالات ومواضيع مستدة على البحث العلمي',
  msg_home_boost_your_health_sub2_title: 'تمارين وألعاب تدعم عافيتك النفسية',
  msg_home_boost_your_health_sub3_title: 'أهداف مخصصة ومتابعة مستمرة',
  msg_home_boost_your_health_sub4_title: 'مسارات تأمل تحملها معك أينما ذهبت ',
  msg_home_newsletter_title: 'ابق على اطلاع على آخر الأفكار في مجال الصحة النفسية',
  msg_home_newsletter_title_alt:
    'لا تفوت آخر المستجدات والنصائح المتعلقة بالصحة النفسية والتي ستصلك أسبوعيا عبر بريدك الإلكتروني',
  msg_home_newsletter_success_title:
    'أهلا بك في أسرة مايندتيلز! نحن سعيدون بقرارك للانضمام إلينا. في حال رغبت بالتواصل معنا يمكنك مراسلتنا على  social@mindtales.me، يسعدنا دائما الاستماع لآراء أفراد أسرتنا :-)',
  msg_home_newsletter_success_text:
    'أهلا بك في أسرة مايندتيلز! نحن سعيدون بانضمامك إلينا. في حال رغبت بالتواصل معنا يمكنك مراسلتنا على  social@mindtales.me، يسعدنا دائما الاستماع لآراء أفراد أسرتنا :-)',
  msg_home_get_started_title: 'هل أنت جاهز لبدء الرحلة؟',
  msg_home_get_started_text: 'نقدم لك دعماً نفسياً رقمياً شاملاً يناسب احتياجاتك',
  msg_home_get_started_sub_title: 'اشترك الآن',
  msg_home_get_started_sub_text: 'نقدم لك دعماً نفسياً رقمياً شاملاً يناسب احتياجاتك',
  msg_home_get_started_video_text: 'محادثة فيديو مباشرة ',
  msg_home_get_started_phone_text: 'مكالمة صوتية ',
  msg_home_benefits_title: 'الفوائد التي ستحصل عليها باستخدام مايندتيلز',
  msg_home_benefits_fit_your_schedule: 'تحكم بالوقت الذي تخصصه لتلقي الدعم النفسي بما يتناسب مع جدولك',
  msg_home_benefits_24_support: 'احصل على الدعم بنقرة واحدة فقط على مدار الساعة ',
  msg_home_benefits_find_ideal_terapist:
    'تواصل مع المختص المثالي الذي يناسب احتياجاتك مع إمكانية تغييره متى شئت بدون أي تكلفة إضافية',
  msg_home_benefits_share_your_preferences: 'ارسم خط رحلتك معنا كما ترغب بمشاركتنا الخيارات التي تفضلها',
  msg_home_feedback_name: 'د.لويس لامبرت',
  msg_home_feedback_using_for: 'مدير برنامج السعادة وجودة الحياة !HappinessMatters@',
  msg_home_feedback_text:
    '“الحقيقة هي أن جميع ضروريات الحياة يجب أن تكون متاحة ومتوفرة على مدار الساعة، ومعفية ممن ألقاء الأحكام. ما الذي يستثني الصحة النفسية من هذا كله إذاً؟"',
  msg_home_questionnaires_title: 'اختبارات قصيرة مثبتة علمياً',
  msg_home_questionnaires_text:
    'الوعي الذاتي هو الخطوة الأولى نحو التقدم. ستمنحك هذه الاختبارات رؤية أكثر وضوحاً عن حالة صحتك النفسية في الوقت الحال ',
  msg_home_questionnaires_question_anxious: 'هل تشعر بالإنهاك أو القلق؟',
  msg_home_questionnaires_question_personality_type: 'اكتشف نمط شخصيتك وأسلوب تواصلك مع الآخرين ',
  msg_home_questionnaires_question_feeling_sad: 'هل تشعر بالإحباط مؤخراً؟',
  msg_home_for_employers_title: 'لأصحاب الأعمال',
  msg_home_for_employers_text:
    'جودة الحياة والعافية التي يتمتع بها فريق عملك هي المؤشر الأول لنجاح مؤسستك! سهل على موظفيك أخذ خطوات إيجابية واستباقية نحو صحة نفسية وعاطفية أفضل.',
  msg_home_questionnaire_anxiety_title: 'كم مرة أزعجك أحد الأمور التالية خلال الأسبوعين الماضيين؟',
  msg_home_questionnaire_anxiety_sub_title:
    'لا بأس إن شعرت بالإرهاق أو القلق أو التوتر. فهم مشاعرك هو الخطوة الأولى باتجاه تطوير الذات. هذا الاختبار القصير مثبت علميا، أجب عن الأسئلة لتكتشف المزيد عن صحتك النفسية.',
  msg_home_questionnaire_depression_title: 'كم مرة أزعجك أحد الأمور التالية خلال الأسبوعين الماضيين؟',
  msg_home_questionnaire_depression_sub_title:
    'استكشاف الذات هو متعة حقيقية، كما أنه ضروري جداً لحياة صحية مليئة بالرضى. قم بإجراء هذا الاختبار القصير لتعرف أكثر عن صحتك العاطفية والنفسية، وما إذا كان بإمكانك الاستفادة من دعم إضافي.',
  msg_home_questionnaire_personality_type_title: 'أرى نفسي كشخص ...',
  msg_home_questionnaire_personality_type_sub_title: 'حدد مدى موافقتك على كل من العبارات التالية',
  msg_about_main_title: 'مهمتنا',
  msg_about_main_text:
    'هدفنا الأكبر هو محاربة وصمة العار الملازمة للصحة النفسية لبناء مجتمع صحي ومزدهر. نقدم تقنيات فعالة لعلاج الاكتئاب في أبوظبي باستخدام أحدث وسائل التكنولوجيا التي تسهل الوصول إلى خدمات الدعم النفسي. نحن نقدم لك أدوات مبنية على البحث العلمي تساعد على التعافي النفسي، بالإضافة إلى تمارين علمية وخدمات الإرشاد النفسي وذلك لنوفر لك عناية متخصصة ومساحة آمنة. ',
  msg_about_our_values_title: 'قيمنا ',
  msg_about_our_values_community_title: 'مجتمعنا',
  msg_about_our_values_community_text:
    'تمكنك ندواتنا المجتمعية وسلسلة المدونات الصوتية المحفوظة لدينا ومنشوراتنا على وسائل التواصل الاجتماعي من التواصل مع أفراد ومرشدين يشابهونك في التفكير بصحتهم النفسية ويشاركونك في رحلة السعي وراء تعزيزها وتحسينها.',
  msg_about_our_values_empowerment_title: 'التمكين',
  msg_about_our_values_empowerment_text:
    'العناية الذاتية ليست بالأمر السهل - نحن هنا بجانبك في الأوقات الصعبة. اعرف المزيد عن مختصي الصحة النفسية لدينا والمتواجدين لتوفير الدعم لك على طول الطريق، واحرص على مساعدتنا في التحقق يومياً من مدى صحة عاداتك كي نستطيع التدخل عندما لا تكون الأمور في أفضل حال لها.',
  msg_about_our_values_inclusivity_title: 'الشمولية ',
  msg_about_our_values_inclusivity_text:
    'ترحب بك مايندتيلز بكل حماس  وبعض النظر عن خلفيتك وبيئتك وجنسيتك للانضمام إلى مجتمعها، حيث هناك جهد مشترك من ناحيتك ومن ناحيتنا لتتويج رحلتك معنا بالحصول على صحة نفسية أفضل وعافية دائمة. ',
  msg_about_our_advisors_title: 'مستشارونا',
  msg_about_our_advisors_subtitle1: 'إمانويل ستيب، MD',
  msg_about_our_advisors_subtext1:
    'د.ستيب هو أستاذ ورئيس قسم الطب النفسي في جامعة الإمارات. قام بنشر أكثر من 350 مجلة تمت مراجعتها من قبل الزملاء، وتلقى العديد من الجوائز، من بينها جائزة زميل متميز في الجمعية الأمريكية للطب النفسي وجائزة كليغورن من الجمعية الكندية للطب النفسي.',
  msg_about_our_advisors_subtitle2: 'ناهدة نياز أحمد، MD',
  msg_about_our_advisors_subtext2:
    'د.أحمد هي استشاري طب نفسي حاصل على البورد الأمريكي، وتشغل حالياً منصب رئيس قسم ورئيس المجلس الإكلينيكي للطب النفسي في شركة أبوظبي للخدمات الصحية. كما أنها الطبيب القائم على الخطة التي أطلقتها دائرة الصحة في أبوظبي لدعم الصحة النفسية. تخرجت د.أحمد من برنامج الإقامة في جامعة "توفت" وحصلت على الزمالة من جامعة هارفارد.',
  msg_about_our_advisors_subtitle3: 'حاميت وات',
  msg_about_our_advisors_subtext3:
    'حاميت هو شريك في مشروع Upfront. وهو مؤسس ورئيس شركة bLife، وهي شركة ذات علاقة بالابتكار الصحي. كما أنه مؤسس ورئيس شركة MoviePass. تلقى حامت العديد من الجوائز في الابتكار التقني وريادة الأعمال، من بينها "جائزة أفضل 10 مبتكرين من ذوي البشرة السمراء". كما أطلق عليه اسم "46 أفضل الأمريكيين الأفارقة في مجال التكنولوجيا”',
  msg_employers_hero_title: 'لأصحاب الأعمال',
  msg_employers_hero_text:
    'إن الدعم النفسي والعاطفي في بيئة العمل هو أمر بغاية الأهمية لأنه يؤثر بشكل مباشر على إنتاجية الموظف وعافيته بشكل عام. ساعد موظفيك في الحصول على الدعم النفسي الملائم!',
  msg_employers_toolkit_text:
    '%70 من الموظفين لا يطلبون المساعدة بسبب وصمة العار الملازمة للصحة النفسية وصعوبة الوصول إلى الخدمات. تتكبد المؤسسات ما يقارب عشرة آلاف دولار سنوياً من تكاليف الرعاية الصحية لكل موظف يعاني من مشاكل نفسية.',
  msg_employers_personalised_care_title: 'رعاية متخصصة متاحة للجميع',
  msg_employers_personalised_care_text:
    'راحتك هي أولويتنا. بدعم فريق مايندتيلز، سنبذل أقصى جهدنا كي نتأكد من أن رحلتك معنا تسير بشكل سلس وآمن يركز عليك أنت فقط ويلبي احتياجاتك.',
  msg_employers_personalised_care_problem1: 'الاكتئاب ',
  msg_employers_personalised_care_problem2: 'الصدمة والحزن ',
  msg_employers_personalised_care_problem3: 'الأمراض المزمنة ',
  msg_employers_personalised_care_problem4: 'القلق والتوتر',
  msg_employers_personalised_care_problem5: 'الغضب',
  msg_employers_personalised_care_problem6: 'الإنتاجية ',
  msg_employers_personalised_care_problem7: 'العلاقات',
  msg_employers_personalised_care_problem8: 'النوم ',
  msg_employers_personalised_care_problem9: 'والمزيد...',
  msg_employers_business_title: 'لماذا يحتاج مكان عملك إلى مايندتيلز؟',
  msg_employers_business_sub_percent1: '%100',
  msg_employers_business_sub_title1: 'يرغبون في التغيير',
  msg_employers_business_sub_text1: ' %100 من الموظفين يرغبون في إحداث تغييرات إيجابية في حياتهم.',
  msg_employers_business_sub_percent2: '%84',
  msg_employers_business_sub_title2: 'لاحظوا انخفاضا في الأعراض',
  msg_employers_business_sub_text2: '%84 من المتلقين انخفضت لديهم أعراض الاكتئاب بعد تلقي الدعم النفسي الرقمي',
  msg_employers_business_sub_percent3: '%83',
  msg_employers_business_sub_title3: 'يرغبون بالحلول الرقمية التي تقدم عن بعد باستخدام التكنولوجيا الحديثة',
  msg_employers_business_sub_text3:
    '%83 يرون أن الدعم العاطفي والنفسي المقدم عن بعد هو خيار مناسب ومفيد لصحة نفسية أفضل',
  msg_employers_productivity_title: 'إنتاجية الموظف وعافيته',
  msg_employers_productivity_text: 'توفير الدعم النفسي للموظفين يؤثر إيجاباً على إنتاجيتهم ويرفع من معنوياتهم ',
  msg_employers_productivity_source: 'المصدر:',
  msg_employers_productivity_source_link: 'مجلة التكنولوجيا والعلوم السلوكية 4، 1-5 (2019)',
  msg_employers_productivity_sub_graphic1: 'خسارة أقل في ساعات العمل',
  msg_employers_productivity_sub_graphic2: 'زيادة في الإنتاجية والتحفيز',
  msg_employers_productivity_sub_graphic3: 'أخطاء أقل في العمل',
  msg_employers_feedback_person1: 'د. ناهدة أحمد',
  msg_employers_feedback_position1: 'استشاري في الطب النفسي، حاصل على التدريب في جامعة هارفارد الطبية',
  msg_employers_feedback_comment1:
    '“إن رحلة التعافي من الأزمة العالمية التي تسبب بها الوباء والتي أثرت سلبا على الصحة النفسية حول العالم قد تكون طويلة وشاقة. على الرغم من هذه الظروف الصعبة، لا يزال هناك بعض الحلول التي تم تصميمها للعناية بالعقل وتغذيته. ماينرتيلز تقدم بعضا من هذه الحلول، تمارين تأمل مركزة، وبعض التعديلات السلوكية التي تساعد في الحفاظ على جودة وعافية الصحة النفسية"',
  msg_employers_lets_work_together: 'يمكن لفريق مايندتيلز أن يساهم في ازدهار مؤسستك. هيا نعمل معاً',
  msg_therapists_hero_title: 'لمزودي الرعاية الصحية',
  msg_therapists_hero_text:
    'باستخدامك منصتنا الممتدة والتي تضم مجموعة واسعة من أفضل المختصين، ستوفر الوقت وتضاعف الربح. يوفر المختصون الكثير من الوقت بتقديم استشارات التعافي النفسي عبر الإنترنت من راحة منازلهم.  ',
  msg_therapists_goal_consumerized_healthcare: 'تشكل الوسائل الرقمية ثورة في مجال الرعاية الصحية',
  msg_therapists_goal_title:
    'في الوقت الحالي - ما بعد الجائحة - 64% من متلقي الرعاية الصحية سيفضلون الحصول على الدعم النفسي والاستشارات بشكل افتراضي عبر وسائل التكنولوجيا المختلفة. ',
  msg_therapists_goal_text: '<span>%62</span> من العملاء يفضلون تلقي الدعم النفسي والاستشارات عبر الإنترنت',
  msg_therapists_goal_text1:
    '%90 من الرواد في قطاع الرعاية الصحية يرون الشراكات الرقمية ضرورة ملحة لتحقيق النجاح على المدى الطويل. ',
  msg_benefits_title: 'اكسب بينما تجلس مرتاحاً في مكانك',
  msg_benefits_sub_title1: 'تصورات أفضل عن العلاقة بين الاستشاري والعميل',
  msg_benefits_sub_text1:
    'تقدم منصتنا الآمنة حلولاً رقمية جديدة كي تصمم تجربة عملائك بما يتلاءم مع احتياجاتهم. باستخدام وسائل عديدة تم تطويرها من قبل المختصين، يمكنك دعمهم خلال رحلتهم ومتابعة تقدمهم خطوة بخطوة.',
  msg_benefits_sub_title2: 'سهولة الوصول في أي زمان ومن أي مكان',
  msg_benefits_sub_text2:
    'الفائدة الرائعة لتقديم الاستشارات عن بعد هي أنك تعمل في راحة منزلك. باستخدام منصتنا يمكنك الوصول إلى نظام مرن وسهل الاستخدام على مدار الساعة باستخدام نظام الحوسبة السحابية، وهو نظام يشمل مساحات تخزين للبيانات والاحتفاظ بنسخ احتياطية وما إلى ذلك. فريقنا متاحٌ دائماً لدعمكم في انسيابية تجربتكم.',
  msg_benefits_sub_title3: 'أقصى قدر من الكفاءة والأرباح',
  msg_benefits_sub_text3:
    'استخدم وسائل مساعدة مثبتة علمياً كي تزود عملاءك بتجربة رعاية نفسية شاملة. نوفر لك البيانات والتحليلات اللازمة كي تتمكن من توجيه مصادرك واستخدامها كيفما تستدعي الحاجة إليها.',
  msg_articles_title: 'مقالاتنا ',
  msg_article_other_articles: 'المزيد من مقالاتنا',
  msg_article_date_with_time: '{{date}} - {{time}}',
  msg_podcasts_subtitle: 'The MindTales Collective • {{date}}',
  msg_podcast_other_podcasts: 'المزيد من المدونات الصوتية',
  msg_home_carousel_section_title: 'مقالاتنا',
  msg_contact_us_title: 'تواصل معنا',
  msg_contact_us_email: 'hello@mindtales.me',
  msg_contact_us_adress:
    'الطابق 16، برج الختم، مربع سوق أبوظبي العالمي، جزيرة المارية، ص.ب. 128666، أبوظبي، الإمارات العربية المتحدة',
  msg_contact_us_form_title: 'يسعدنا أن نستمع إليك!',
  msg_contact_us_form_text:
    'هل لديك أي أسئلة أو اقتراحات أو أفكار ترغب في مشاركتها معنا؟ لا تتردد في مراسلتنا وسنتواصل معك بأسرع مما تتخيل!',
  msg_get_access_title: 'والآن حمل التطبيق في الأسفل لتبدأ رحلتك ',
  msg_get_access_subtitle: 'تمكنت من الدخول ',
  msg_install_app: 'حمل التطبيق',
  msg_trademark: ' © 2021 مايندتيلز. جميع الحقوق محفوظة ',
  msg_therapists_page_feedback_name: 'إيان',
  msg_therapists_page_feedback_using_for: 'مستخدم لتطبيق MindTales',
  msg_therapists_page_feedback_text:
    '"أعجبني حقا استخدام تطبيق Mindtales فهو مصمم بشكل يناسب الاحتياج الشخصي كما أنه سهل الاستخدام. وأعتقد أن قدرة Mindtales على استضافة مختصين متفاعلين عن طريق التطبيق نفسه بدلا من توفير منصة للعثور على مختص كما في "',
  msg_about_featured_in_title: 'المعلنون',
  msg_therapists_page_feedback_name_2: 'فدوى',
  msg_therapists_page_feedback_using_for_2: ' مستخدم لتطبيق مايندتيلز',
  msg_therapists_page_feedback_text_2:
    '"تطبيق MindTales سهل الاستخدام وجيدٌ جداً لمن أراد بناء عادات إيجابية للعناية الذاتية. على عكس التطبيقات الأخرى، لم يكن التطبيق صعباً أو معقداً وجعلني أشعر أني وجدت ما أريد. أنصح به بشدة لأولئك الذين يمرون بضغوط يومية ولديهم القليل فقط من الوقت ويحتاجون إلى متنفس بشكل متكرر خلال اليوم!',
} as const;

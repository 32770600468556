export default {
  msg_default_website_title: 'MindTales',
  msg_default_meta_title: 'Mental wellness support in your pocket',
  msg_default_meta_description:
    'Self-care is more than a band aid. At MindTales, we want you to invest in your emotional health for the long-run. We offer you convenient access to online wellness support, anytime and anywhere. Chat with our wellness specialists from the comfort of your home!',

  // Image Alt
  msg_img_waving_man: 'Man waving',
  msg_img_man_sitting_with_mindtales: 'Man sitting with a Mindtales application open on his phone',
  msg_img_open_letter: 'An open envelope with a letter inside in colorful background',
  msg_img_success_star: 'A yellow start shown after successful registration for a newsletter',
  msg_img_success_thumb: 'A thumbs up shown after successful registration for a newsletter',
  msg_img_mobile_app_schedule_sesion: 'Mindtales application with a schedule next session screen open',
  msg_img_mobile_app_meditation: 'Mindtales application with a meditation screen open',
  msg_img_feedback: 'A person who was provided with a lifechanging experience by Mindtales application',
  msg_img_conversation: 'A conversation between two people',
  msg_img_walking_with_IPhone: 'A person holding an iPhone with a Mindtales application open',
  msg_img_employers_feedback: 'Employers who left feedback about Mindtales',
  msg_img_therapist_page: 'Therapist page after you login',
  msg_img_goal: 'Finish line with a goal sign',
  msg_img_advisor: 'Portrait of an advisor',
  msg_img_featured_in: 'Logo of place where mindTales was featured in',

  // Buttons
  msg_btn_get_access: 'Get Access',
  msg_btn_submit: 'Submit',
  msg_btn_learn_more: 'Learn more',
  msg_btn_request_demo: 'Request Demo',
  msg_btn_check_our_toolkit: 'Check Our Toolkit',
  msg_btn_accept: 'Accept',
  msg_btn_decline: 'Decline',
  msg_btn_next: 'Next',

  // Form fields
  msg_field_email_label: 'Email',
  msg_field_email_label_req: 'Email*',
  msg_field_email_placeholder: 'Enter your email',
  msg_field_work_email_label: 'Email',
  msg_field_work_email_label_req: 'Email*',
  msg_field_work_email_placeholder: 'Enter your email',
  msg_field_name_label: 'Name',
  msg_field_name_label_req: 'Name*',
  msg_field_name_placeholder: 'Enter your name',
  msg_field_phone_label: 'Phone number',
  msg_field_phone_label_req: 'Phone number*',
  msg_field_phone_placeholder: 'Enter your phone number',
  msg_field_how_did_you_hear_about_us: 'How did you hear about us?',
  msg_field_how_did_you_hear_about_us_req: 'How did you hear about us?*',
  msg_field_message: 'Message',
  msg_field_message_req: 'Message*',
  msg_field_message_placeholder: 'Your message to us',

  // Modals
  msg_modal_request_demo_title: 'MindTales would love to have you join us!',
  msg_success_wizard_title: 'Success',
  msg_success_wizard_subtitle: 'You have successfully completed the questionnaire.',
  msg_success_wizard_email_title: 'Enter your email to see your results.',
  msg_success_wizard_email_subtitle: 'Subscribe to our newsletter.',

  // Errors
  msg_error_field_required: 'Required',
  msg_error_passwords_not_equal: 'Passwords not equal',
  msg_error_invalid_email: 'Invalid email',
  msg_error_unexpected: 'Unexpected error',
  msg_error_already_verified: 'User already verified',
  msg_error_password_key_expired: 'Password key expired',
  msg_error_exercise_is_locked: 'Exercise is locked',
  msg_error_exercise_is_deleted: 'Exercise is deleted',
  msg_error_not_found: 'Not found',
  msg_error_bad_credentials: 'Invalid login data',
  msg_error_login_user_email_not_verified: 'User email not verified',
  msg_error_no_such_language: 'No such language',
  msg_error_password_is_incorrect: 'Incorrect password',
  msg_error_user_id_is_not_in_token: 'Invalid token',
  msg_error_login_invalid_user_type: 'Invalid user type. You are not allowed to login to the system',
  msg_error_email_already_exist: 'Email already used',
  msg_error_invalid_phone_format: 'Invalid phone',
  msg_error_invalid_date: 'Invalid date',
  msg_error_birth_date_should_be_in_past: 'Birth date should be in past',
  msg_error_member_exists: 'You have already subscribed',

  // Password requirements
  msg_password_requirement_length: 'More than 8 characters',
  msg_password_requirement_upper_and_lower_letters: 'Upper and lowercase letters',
  msg_password_requirement_numbers_or_symbols: 'Contains a number or symbol',

  // time
  msg_time_display_0: '{{time}} min',
  msg_time_display_1: '{{time}} min',
  msg_time_display_2: '{{time}} min',
  msg_time_display_3_10: '{{time}} min',
  msg_time_display_11: '{{time}} min',

  //Pages
  msg_default_page: 'Page',
  msg_home_page_title: 'MindTales: Leading Corporate Wellness Platform in the MENA Region',
  msg_about_us_page: 'Who We Are',
  msg_how_it_works_page: 'How MindTales Works',
  msg_knowledge_hub_page: 'Knowledge Hub',
  msg_article_page: 'Article Page',
  msg_podcast_page: 'Podcast Page',
  msg_employers_page: 'Employers Page',
  msg_therapists_page: 'For Therapists',
  msg_healthcare_providers_page: 'For Healthcare Providers',
  msg_contact_us_page: 'Contact Us',
  msg_faq_page: 'Frequently Asked Questions',
  msg_terms_page: 'Terms and Conditions',
  msg_privacy_page: 'Privacy Policy',
  msg_careers_page: 'Careers',
  msg_community_page: 'Community Guidelines',
  msg_not_found_page_title: 'Not Found',
  msg_page_with_param_page_title: 'Page With Param',
  msg_get_access_page: 'Get Access',
  msg_careers_page_title: 'Careers at MindTales',
  msg_guidelines_page_title: 'MindTales Community Guidelines',

  // Pages meta descriptions
  msg_home_page_meta_description:
    'Professional Therapy from Any Device. Text, Chat & Video Options Available. Convenient, Professional Online Therapy Anytime, Anywhere. Get the Help You Need Now from MindTales Mental Health Experts.',
  msg_how_it_works_page_meta_description:
    'MindTales provides online mental health support. Engage with virtual mental health counsellor from the comfort of your own space. Download app now!',
  msg_knowledge_hub_page_meta_description:
    'Research Backed Knowledge Hub. Wellness Activities And Games. Personalised Goals and Tracked Progress. Mental health support services Abu Dhabi.',
  msg_employers_page_meta_description:
    'Online wellness programs for your employees.Online wellness program offers mental health counseling 24/7, personal training, guided yoga & meditation, nutrition therapy.',
  msg_therapists_page_meta_description:
    'Therapist can provide online wellness programs from the comfort of their own home. Online wellness services provide the much-needed relief and solution',
  msg_contact_us_page_meta_description:
    'Are you Looking Best online depression treatment in Dubai? MindTales provides mental health support online. Contact us now ! Download mental health app.',
  msg_about_us_page_meta_description:
    'MindTales provides online mental health depression counselling in Abu Dhabi. Our mental health app provides online wellness support, anytime, anywhere.',
  msg_faq_page_meta_description: 'Frequently Asked Questions. What is MindTales? Is MindTales the right place for me?',
  msg_terms_page_meta_description:
    'The following are the Terms and Conditions (the "Agreement") which govern your access and use of our online platform through which counseling is provided.',
  msg_privacy_page_meta_description:
    "Welcome to MindTales' Privacy Policy. MindTales respects your privacy and is committed to protecting your personal data.",
  msg_careers_page_meta_description:
    'For open positions, please check our LinkedIn page. And if you’d like to get in touch, share your CV, portfolio or cover letter at careers@mindtales.me.',
  msg_guidelines_page_meta_description:
    'MindTales Community Guidelines. Overview. We strive to make our MindTales digital platforms and social media pages safe and empowering spaces',

  // Reusable
  msg_landing_vs: 'Online vs In-Person Counselling',
  msg_vs_table_mindtales_only: 'MindTales Online Counselling',
  msg_vs_table_face_to_face: 'In-Person Counselling',
  msg_vs_table_checklist1: 'Connect with licensed wellness specialists',
  msg_vs_table_checklist2: 'In-person meetings',
  msg_vs_table_checklist3: 'Audio and video sessions',
  msg_vs_table_checklist4: 'Access to scheduling and feedback with one tap',
  msg_vs_table_checklist5: 'Switch between counsellors and wellness specialists across the globe',
  msg_vs_table_checklist6: 'Get help anytime and anywhere',
  msg_vs_table_checklist7: 'Algorithmic counsellor-client matching',
  msg_vs_table_checklist8: 'Access to gamified wellness exercises',
  msg_vs_table_checklist9: 'Be a part of a community of like-minded individuals',

  // Homepage
  msg_home_hero_title: 'Mental wellness support in your pocket',
  msg_home_hero_subtitle: 'Online mental wellness program',
  msg_home_hero_text:
    'Self-care is more than a band aid. At MindTales, we want you to invest in your emotional health for the long-run. We offer you convenient access to online wellness support, anytime and anywhere. Chat with our wellness specialists from the comfort of your home!',
  msg_home_how_it_works_title: 'How MindTales Works',
  msg_home_how_it_works_sub1_title: 'Security and Privacy',
  msg_home_how_it_works_sub1_text:
    'We ensure that every interaction of yours at MindTales is fully encrypted and secure. We never share your information with others. Your data is fully protected by the law. ',
  msg_home_how_it_works_sub2_title: 'Science-Backed Wellness Games',
  msg_home_how_it_works_sub2_text:
    'Access wellness games and resources to explore and strengthen your mental health. We personalise your experience and guide you through your journey using tools developed by experts. ',
  msg_home_how_it_works_sub3_title: 'Connect With Experts',
  msg_home_how_it_works_sub3_text:
    'Choose from our extended network of counsellors and wellness specialists across the globe. Engage in confidential interactions from the comfort of your own space.',
  msg_home_boost_your_health_title: 'Boost Your Emotional Health',
  msg_home_boost_your_health_text:
    'It’s in the smallest moments that mental health support can really make a difference. We get that. Each feature of our application is curated with your comfort in mind.',
  msg_home_boost_your_health_sub1_title: 'Research-Backed Knowledge Hub',
  msg_home_boost_your_health_sub2_title: 'Wellness Activities And Games',
  msg_home_boost_your_health_sub3_title: 'Personalised Goals and Tracked Progress',
  msg_home_boost_your_health_sub4_title: 'Meditation Tracks On-The-Go',
  msg_home_newsletter_title: 'Don’t miss out on the latest mental health insights!',
  msg_home_newsletter_title_alt:
    'Don’t miss out on the latest mental health insights and tips delivered to your inbox weekly!',
  msg_home_newsletter_success_title: 'Thank you for subscribing!',
  msg_home_newsletter_success_text:
    "Welcome to the MindTales community! We're glad you signed up. If you'd like to say hi, drop us a line at social@mindtales.me. We love hearing from our community members :-)",
  msg_home_get_started_title: 'Ready to get started?',
  msg_home_get_started_text: 'We offer comprehensive online mental health support to meet all your needs.',
  msg_home_get_started_sub_title: 'Get access.',
  msg_home_get_started_sub_text: 'We offer comprehensive online mental health support  to meet all your needs.',
  msg_home_get_started_video_text: 'Live video',
  msg_home_get_started_phone_text: 'Phone',
  msg_home_benefits_title: 'Benefits of MindTales',
  msg_home_benefits_fit_your_schedule: 'Tailor your mental health support to fit your schedule',
  msg_home_benefits_24_support: 'With 24/7 access, our support is one tap away',
  msg_home_benefits_find_ideal_terapist: 'Match with your ideal wellness specialist and switch at no extra cost',
  msg_home_benefits_share_your_preferences: 'Take charge of your journey by sharing your preferences',
  msg_home_feedback_name: 'Dr. Louise Lambert',
  msg_home_feedback_using_for: 'Director of Happiness and Wellbeing Programs @HappinessMatters!',
  msg_home_feedback_text:
    '"The truth is that life’s necessities should be accessible, available 24/7 and non-judgmental. Why should mental health be any different?."',
  msg_home_questionnaires_title: 'Science-Backed Quizzes',
  msg_home_questionnaires_text:
    'Self-awareness is the first step forward! These assessments will give you better insights into your present mental health.',
  msg_home_questionnaires_question_anxious: 'Are you feeling overwhelmed or anxious?',
  msg_home_questionnaires_question_personality_type: 'Discover your communication style and personality type. ',
  msg_home_questionnaires_question_feeling_sad: 'Have you been feeling down for a while?',
  msg_home_for_employers_title: 'For Employers',
  msg_home_for_employers_text:
    "The wellbeing of your team is a key component of your organisation's effectivess. Make it simple for your employees to take positive and proactive steps to boost their emotional health.",
  msg_home_carousel_section_title: 'Our Blog',
  msg_home_questionnaire_anxiety_title: 'How often have you been bothered by the following over the past 2 weeks?',
  msg_home_questionnaire_anxiety_sub_title:
    "It's okay to feel overwhelmed, anxious or nervous. Understanding your emotional health is the first step to making room for self-growth. Take this science-backed quiz to find out more about your mental health. ",
  msg_home_questionnaire_depression_title: 'How often have you been bothered by the following over the past 2 weeks?',
  msg_home_questionnaire_depression_sub_title:
    'Self-discovery is an essential part of living a healthy and fulfilling life. Take this quiz to learn more about your emotional health and whether you could benefit from extra support. ',
  msg_home_questionnaire_personality_type_title: 'I see myself as someone who...',
  msg_home_questionnaire_personality_type_sub_title:
    'For each of the following items, indicate your level of agreement.',

  // ABOUT US
  msg_about_main_title: 'Our Mission',
  msg_about_main_subtitle: 'Online mental health depression counselling in Abu Dhabi',
  msg_about_main_text:
    'Our core aim is to fight mental health stigma to build healthy and thriving communities. We want to leverage digital solutions to increase accessibility to quality emotional health support. We offer you science-backed wellness tools, activities and online counselling for safe, convenient and personalised care.',
  msg_about_our_values_title: 'Our Values',
  msg_about_our_values_community_title: 'Community',
  msg_about_our_values_community_text:
    'Our community seminars, podcast series and social media pages connect you with like-minded mentors and individuals working on their wellness journey.',
  msg_about_our_values_empowerment_title: 'Empowerment',
  msg_about_our_values_empowerment_text:
    'Self-care isn’t easy. We’re here for you in these tough times. Lean on our wellness specialists for support and tailor your check-ins so we can do our best to support you.',
  msg_about_our_values_inclusivity_title: 'Inclusivity',
  msg_about_our_values_inclusivity_text:
    'Regardless of your background, MindTales is excited to welcome you to our community for a joint effort to champion your personal wellness journey. ',
  msg_about_our_advisors_title: 'Our Advisors',
  msg_about_our_advisors_subtitle1: 'Emmanuel Stip',
  msg_about_our_advisors_subtext1:
    'Dr. Stip is a Professor and the Chair of Psychiatry at United Arab Emirates University (UAEU). He’s published over 350 peer-reviewed journals and received many awards including Distinguished Fellow of the American Psychiatric Association and The Cleghorn Award from the Canadian Psychiatric Association.',
  msg_about_our_advisors_subtitle2: 'Nahida Nayaz Ahmed',
  msg_about_our_advisors_subtext2:
    'Dr. Ahmed is an American board-certified psychiatrist. She is currently the Chair of Department and Chair of Behavioral Health Council at Abu Dhabi Health Services Co (SEHA). She is also the clinical lead for the Mental Health Plan under the Department of Health in Abu Dhabi. Dr. Ahmed completed Tuft’s University Residency program and a Harvard University Fellowship.',
  msg_about_our_advisors_subtitle3: 'Hamet Watt',
  msg_about_our_advisors_subtext3:
    'Hamet is a Venture Partner at Upfront Ventures. He’s the Co-Founder and Chairman of bLife, a wellness innovation company. He’s also the Co-Founder and Chairman of MoviePass. Hamet has received many awards for tech innovation and entrepreneurship, including the “Top 10 Black Innovators Award". He was also named as “46 Most Important African-Americans in Technology.”',
  msg_about_featured_in_title: 'Featured In',

  // Employers Page
  msg_employers_hero_title: 'For Employers',
  msg_employers_hero_text:
    'Emotional wellness support at work is a necessity for employee productivity and wellbeing. Make it simple for your employees to boost their emotional health.',
  msg_employers_toolkit_text:
    '70% of employees do not seek help due to stigma and access barriers. Organisations gather up to $10K additional annual healthcare and productivity costs for each employee suffering.',
  msg_employers_personalised_care_title: 'Personalised Care for All',
  msg_employers_personalised_care_text:
    'Your comfort is our priority. With the support of the MindTales team, we’ll do our best to ensure that your wellness journey is smooth, secure and centered around you.',
  msg_employers_personalised_care_problem1: 'Depression',
  msg_employers_personalised_care_problem2: 'Trauma & grief',
  msg_employers_personalised_care_problem3: 'Chronic illness',
  msg_employers_personalised_care_problem4: 'Anxiety & stress',
  msg_employers_personalised_care_problem5: 'Anger',
  msg_employers_personalised_care_problem6: 'Productivity',
  msg_employers_personalised_care_problem7: 'Relationships',
  msg_employers_personalised_care_problem8: 'Sleep',
  msg_employers_personalised_care_problem9: 'And more...',
  msg_employers_business_title: 'Why does your business need MindTales?',
  msg_employers_business_sub_percent1: '100%',
  msg_employers_business_sub_title1: 'Want Change',
  msg_employers_business_sub_text1: '100% of employees want to make positive life changes.',
  msg_employers_business_sub_percent2: '84%',
  msg_employers_business_sub_title2: 'Saw Symptom Reduction',
  msg_employers_business_sub_text2:
    '84% experience reduced depressive symptoms following digital wellness interventions.',
  msg_employers_business_sub_percent3: '83%',
  msg_employers_business_sub_title3: 'Desire Digital Solutions',
  msg_employers_business_sub_text3: '83% say digital mental health solutions are convenient and useful.',
  msg_employers_productivity_title: 'Employee Productivity and Well-Being',
  msg_employers_productivity_text:
    'Offering wellness support to employees positively influences their productivity and boosts worker morale.',
  msg_employers_productivity_source: 'Source:',
  msg_employers_productivity_source_link: 'Journal of Technology in Behavioral Science 4, 1-5 (2019)',
  msg_employers_productivity_sub_graphic1: 'Less work hours missed',
  msg_employers_productivity_sub_graphic2: 'Increase in productivity and motivation',
  msg_employers_productivity_sub_graphic3: 'Fewer mistakes at work',
  msg_employers_feedback_person1: 'Dr. Nahida Ahmed',
  msg_employers_feedback_position1: 'Consultant Psychiatrist, trained at Harvard Medical School ',
  msg_employers_feedback_comment1:
    'Recovery from the global mental health crisis created by the pandemic can be long and arduous. Despite the circumstances, there is support designed to nurture and nourish the mind. Delivered through the MindTales app, focused mindfulness exercises and behavioral modifications allow for the replenishment of a positive mental well-being.',
  msg_employers_lets_work_together: "MindTales can help your business thrive. Let's work together. ",

  // Therapists page
  msg_therapists_hero_title: 'For Healthcare Providers',
  msg_therapists_hero_text:
    'With exclusive access to our extended platform of specialists and clients, you’ll save time, boost your profits and maximise efficiency. Specialists save time by providing online wellness services.',
  msg_therapists_goal_consumerized_healthcare: 'Digital Tools Revolutionising Healthcare',
  msg_therapists_goal_text: '<span>62%</span> of clients prefer to receive virtual wellness support and counselling.',
  msg_benefits_title: 'Earn From The Comfort Of Your Own Space',
  msg_benefits_sub_title1: 'Enhanced Counsellor-Client Insights',
  msg_benefits_sub_text1:
    "Our secure platform offers you digital solutions to personalise your clients' experience. Using the tools developed by our experts, you can support their journey, monitor their progress and support them in the best way possible. ",
  msg_benefits_sub_title2: 'Accessible Anytime And Anywhere',
  msg_benefits_sub_text2:
    "A great benefit of online counselling is that you can work from the comfort of your own space. With our platform, you'll have 24/7  access to our cloud-based services and support. Our team is here to streamline your experience. ",
  msg_benefits_sub_title3: 'Maximised Efficiency and Profits',
  msg_benefits_sub_text3:
    "Utilise our science-backed tools to provide your clients' with a complete wellness experience. We provide you with data and analytics to direct your resources where they are most needed.",

  // Articles
  msg_articles_title: 'Our Articles',
  // Article Page
  msg_article_other_articles: 'Our other articles:',
  msg_article_date_with_time: '{{date}} - {{time}}',

  //Podcasts
  msg_podcasts_subtitle: 'The MindTales Collective • {{date}}',
  msg_podcast_other_podcasts: 'Our other podcasts:',

  // Contact Us Page
  msg_contact_us_title: 'Contact us',
  msg_contact_us_email: 'hello@mindtales.me',
  msg_contact_us_adress:
    '16th Floor, Al Khatem Tower, ADGM, Al Maryah Island, P.O. Box 46617,Abu Dhabi, United Arab Emirates',
  msg_contact_us_form_title: 'We would love to hear from you!',
  msg_contact_us_form_text: `Do you have any questions, comments or thoughts you’d like to share with us? Don’t hesitate to drop us a line - we'll be in touch before you know it!`,

  // Get Access Page
  msg_get_access_title: 'Now install the app below to get started.',
  msg_get_access_subtitle: 'You’re in!',

  // Footer
  msg_install_app: 'Install App',
  msg_trademark: '© 2021 MindTales. All rights reserved',

  msg_therapists_page_feedback_name: 'Ian',
  msg_therapists_page_feedback_using_for: 'MindTales user',
  msg_therapists_page_feedback_text:
    '"I really liked using the MindTales app due to the personalization it offers. I found it very intuitive to use. I also think that MindTales’ ability to directly host therapist interaction via the app—rather than simply providing a platform to find a therapist like in other apps—makes it so much more helpful."',
  msg_therapists_page_feedback_name_2: 'Fadwa',
  msg_therapists_page_feedback_using_for_2: 'MindTales User',
  msg_therapists_page_feedback_text_2:
    '"The MindTales app is simple to use and great for building positive self-care habits. Unlike other apps, it is not fussy and makes me feel understood right away. I highly recommend it for those who struggle with daily stressors, are short on time and need frequent breathers throughout their day!"',
} as const;

import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import AppInitiator from '@Components/AppInitiator';
import Config from '@Config/config';

import reportWebVitals from './reportWebVitals';

import '@Utils/i18n';
import '@Styles/index.module.scss';
import 'swiper/swiper.min.css';

if (Config.SENTRY_DSN) {
  Sentry.init({ dsn: Config.SENTRY_DSN, environment: Config.SENTRY_ENVIRONMENT });
}

if (Config.GOOGLE_TGM_ID) {
  TagManager.initialize({
    gtmId: Config.GOOGLE_TGM_ID,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AppInitiator />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { EnabledLanguageCodes, Language, MapT } from '@Utils/types';

export type AppConfig = {
  academic_degrees: MapT<{ degree: number; text: string }[]>;
  community_guidelines: MapT<{ content: string; title: string }>;
  careers: MapT<{ content: string; title: string }>;
  default_language: EnabledLanguageCodes;
  document_types: MapT<{ document_type: number; text: string }[]>;
  enabled_languages: Language[];
  faq: MapT<{ content: string; title: string }[]>;
  privacy: MapT<{ content: string; title: string }>;
  specialities: MapT<{ speciality: number; text: string }[]>;
  terms: MapT<{ content: string; title: string }>;
  timezones: string[];
  toolkit_pdf: MapT<string>;
  version: string;
};

let APP_SETTINGS: AppConfig | undefined;

export const setAppSettings = (settings: AppConfig) => {
  if (APP_SETTINGS) {
    throw new Error('Trying to change app configuration');
  }
  APP_SETTINGS = settings;
};

export const getAppSettings = () => {
  if (!APP_SETTINGS) {
    throw new Error('Trying get app setting while they are not loaded');
  }
  return APP_SETTINGS;
};

import i18n from 'i18next';
import React, { Suspense, useEffect, useState } from 'react';

import GlobalLoader, { LoaderSizes } from '@Components/GlobalLoader';
import { AppConfig, setAppSettings } from '@Config/appConfig';
import { LocalStorage } from '@Config/constants';
import endpoints from '@Config/endpoints';
import apiInstance from '@Utils/api';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { LoadingState } from '@Utils/types';

const App = React.lazy(() => import('./App'));

const AppInitiator = () => {
  const [loadingState, setLoadingState] = useState(LoadingState.idle);

  const loadAppConfig = async () => {
    setLoadingState(LoadingState.pending);
    try {
      const result = await apiInstance.get<AppConfig>(endpoints.APP_CONFIG);
      const language = getFromLocalStorage(LocalStorage.lang) || result.data.default_language;
      setAppSettings(result.data);
      await i18n.changeLanguage(language);
      setToLocalStorage(LocalStorage.lang, language);
      setLoadingState(LoadingState.resolved);
    } catch (e) {
      setLoadingState(LoadingState.rejected);
    }
  };

  useEffect(() => {
    loadAppConfig();
  }, []);

  if (loadingState !== LoadingState.resolved) {
    return <GlobalLoader size={LoaderSizes.fullScreen} />;
  }

  return (
    <Suspense fallback={<GlobalLoader size={LoaderSizes.fullScreen} />}>
      <App />
    </Suspense>
  );
};

export default AppInitiator;

import classNames from 'classnames';
import React from 'react';

import Icon, { IconSizes } from '@Components/Icon';

import styles from './GlobalLoader.module.scss';

export enum LoaderSizes {
  fullScreen = 'fullScreen',
  fillContainer = 'fillContainer',
  size100 = 'size100',
  size40 = 'size40',
}

interface GlobalLoaderProps {
  size: LoaderSizes;
  className?: string;
}

const GlobalLoader = ({ size, className }: GlobalLoaderProps) => {
  return (
    <div className={classNames(styles.globalLoader, styles[size], className)}>
      <Icon icon="spinner" spin={true} size={IconSizes.l} />
    </div>
  );
};

export default GlobalLoader;

import axios, { AxiosError } from 'axios';
import i18n from 'i18next';

import Config from '@Config/config';
import { LocalStorage } from '@Config/constants';
import endpoints from '@Config/endpoints';

import { deleteFromLocalStorage, getFromLocalStorage, setToLocalStorage } from './localStorage';
// eslint-disable-next-line no-console
if (!Config.API_BASE_URL) console.warn('API_URL is undefined');

const apiInstance = axios.create({
  baseURL: Config.API_BASE_URL,
});

export type RefreshTokenData = {
  access: string;
  refresh: string;
};

const buildAuthHeader = (token: string) => `Token ${token}`;
apiInstance.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage(LocalStorage.userToken);
    const language = getFromLocalStorage(LocalStorage.lang) || i18n.language;
    const headers: Record<string, string> = config.headers;

    if (token !== null) {
      headers.Authorization = buildAuthHeader(token);
    }
    headers['Accept-Language'] = language;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

const refreshAccessToken = async () => {
  const refreshToken = getFromLocalStorage(LocalStorage.refreshToken);
  const tokenStatus = await apiInstance.post<RefreshTokenData>(endpoints.REFRESH_TOKEN, { refresh: refreshToken });
  return tokenStatus.data;
};

apiInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError & { config: { retry?: boolean } }) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && error.response.config.url !== endpoints.REFRESH_TOKEN) {
      if (!originalRequest.retry) {
        originalRequest.retry = true;
        deleteFromLocalStorage(LocalStorage.userToken);
        const tokens = await refreshAccessToken();
        setToLocalStorage(LocalStorage.userToken, tokens.access);
        setToLocalStorage(LocalStorage.refreshToken, tokens.refresh);
        (originalRequest.headers as Record<string, string>).Authorization = buildAuthHeader(tokens.access);
        return axios(originalRequest);
      }
    } else {
      //TODO validate rejected flow
      deleteFromLocalStorage(LocalStorage.userToken);
      deleteFromLocalStorage(LocalStorage.refreshToken);
    }
    return Promise.reject(error);
  },
);

export default apiInstance;
